/**
 * Created by metoikos on 10.02.22.
 * Project: 2nci.com
 */

import React from "react";
import { HomePagePostsQuery } from "../../../graphql-types";
import PostItemLarge from "./PostItemLarge";

interface HomePageShowCaseProps {
  posts: HomePagePostsQuery;
}

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr];
};

const HomePageShowCase: React.FC<HomePageShowCaseProps> = ({ posts }) => {
  const { 0: firstPost } = posts.allMdx.nodes;
  return (
    <>
      <PostItemLarge post={firstPost} />
    </>
  );
};

export default HomePageShowCase;
