import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Hello World",
  "date": "2022-02-10 01:18:18",
  "coverImage": "../../images/first/cover-image.jpg",
  "coverImageAlt": "Hello Worldx",
  "coverImageCredit": "Markus Spiske https://unsplash.com/@markusspiske"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hey there! I'm Yılmaz. I develop software.
I am passionate about learning things and solving problems.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      