import * as React from "react";
import Layout from "../components/layout/Layout";
import { graphql, PageProps } from "gatsby";
import { HomePagePostsQuery } from "../../graphql-types";
import HomePageShowCase from "../components/blog/HomePageShowCase";
// import PostItemLarge from "../components/blog/PostItemLarge";
// import PostItem from "../components/blog/PostItem";
// import ContentCard from "../components/content/ContentCard";

const IndexPage: React.FC<PageProps<HomePagePostsQuery>> = ({ data }) => {
  return (
    <Layout pageTitle="Home Page">
      <HomePageShowCase posts={data} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePagePosts {
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 6) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          coverImageAlt
          coverImageCredit
        }
        id
        body
      }
    }
  }
`;
