/**
 * Created by metoikos on 10.02.22.
 * Project: 2nci.com
 */

import React from "react";

interface ContentCardLargeProps {
  coverImage?: React.ReactNode;
  title?: string;
  text?: React.ReactNode | string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const ContentCardLarge: React.FC<ContentCardLargeProps> = ({
  coverImage,
  title,
  text,
  header,
  footer,
}) => {
  return (
    <div className="flex max-w-xl bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex items-center w-full">
        <div className="w-full">
          {header && (
            <div className="flex flex-row mt-2 px-2 py-3 mx-3">{header}</div>
          )}

          <div className="border-b border-gray-100" />
          {coverImage ? (
            <div className="text-gray-400 font-medium text-sm mb-7 mt-6 mx-3 px-2">
              {coverImage}
            </div>
          ) : (
            <div className="mb-4" />
          )}

          {title && (
            <div className="text-gray-600 font-semibold text-lg mb-2 mx-3 px-2">
              {title}
            </div>
          )}

          {text && (
            <div className="text-gray-500 text-base mb-6 mx-3 px-2">{text}</div>
          )}

          {footer && (
            <div className="flex w-full border-t p-2 border-gray-100">
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentCardLarge;
