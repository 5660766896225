/**
 * Created by metoikos on 11.02.22.
 * Project: 2nci.com
 */

import React from "react";

// interface BlogPageProps {
//   name: string;
// }

const BlogPage: React.FC<any> = () => {
  return <div>Blog posts</div>;
};

export default BlogPage;
