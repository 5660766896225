/**
 * Created by metoikos on 10.02.22.
 * Project: 2nci.com
 */

import React from "react";

//interface FooterProps {
// name: string,
//}

const Footer: React.FC<any> = () => {
  return (
    <footer className="text-sm text-gray-500 py-8 text-medium bg-gray-100">
      <section className="container mx-auto px-4 sm:px-6 lg:px-8 grid md:grid-cols-auto-span items-center gap-10 md:gap-16">
        <p className="text-xs text-center mb-0">
          {new Date().getFullYear()} Yılmaz Uğurlu
        </p>
      </section>
    </footer>
  );
};

export default Footer;
