/**
 * Created by metoikos on 09.02.22.
 * Project: 2nci.com
 */

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";

interface LayoutProps {
  pageTitle: string;
}

type DataProps = {
  site: {
    siteMetadata: {
      title: string;
      siteUrl: string;
    };
  };
};

const Layout: React.FC<LayoutProps> = ({ pageTitle, children }) => {
  const data: DataProps = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {pageTitle
            ? `${pageTitle} - ${data.site.siteMetadata.siteUrl}`
            : data.site.siteMetadata.title}
        </title>
        <link rel="canonical" href="https://2nci.com" />
      </Helmet>
      <Navbar />
      <main className="container mx-auto px-4 sm:px-6 lg:px-8 py-3 md:py-6">
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
