/**
 * Created by metoikos on 10.02.22.
 * Project: 2nci.com
 */

import React from "react";
import { Link } from "gatsby";

const Navbar: React.FC<any> = () => {
  return (
    <header
      className="relative z-10 container mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between h-24 font-semibold text-sm text-navy"
      data-menu-container=""
    >
      <nav aria-label="Logo menu" className="relative z-50 flex">
        <Link to="/" className="px-3">
          ~/2nci
        </Link>
      </nav>
      <button
        data-menu-toggle=""
        aria-expanded="false"
        className="menu-toggle block ml-auto lg:hidden relative z-50"
      >
        <span className="sr-only">Open Main Menu</span>
      </button>
      {/*<nav*/}
      {/*  aria-label="Main menu"*/}
      {/*  className="hidden lg:flex items-center justify-center content-center gap-y-4 flex-1 z-40 fixed inset-0 lg:static bg-violet-50 lg:bg-transparent text-base sm:text-sm"*/}
      {/*>*/}
      {/*  <Link*/}
      {/*    to="/blog"*/}
      {/*    className="text-center mx-auto lg:ml-auto lg:mr-3 hover:text-pink-600"*/}
      {/*    activeClassName="text-pink-800 hover:text-pink-800"*/}
      {/*  >*/}
      {/*    Blog*/}
      {/*  </Link>*/}
      {/*  <Link*/}
      {/*    to="/projects"*/}
      {/*    className="text-center mx-auto lg:mx-3 transition-colors hover:text-pink-600"*/}
      {/*    activeClassName="text-pink-800 hover:text-pink-800"*/}
      {/*  >*/}
      {/*    Projects*/}
      {/*  </Link>*/}
      {/*</nav>*/}
    </header>
  );
};

export default Navbar;
