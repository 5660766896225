/**
 * Created by metoikos on 10.02.22.
 * Project: 2nci.com
 */

import React from "react";
import ContentCardLarge from "../content/card/ContentCardLarge";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";

interface PostItemLargeProps {
  post: any;
}

const PostItemLarge: React.FC<PostItemLargeProps> = ({ post }) => {
  const image = getImage(post.frontmatter?.coverImage);

  return (
    <ContentCardLarge
      coverImage={
        image ? (
          <GatsbyImage image={image} alt={post.frontmatter?.coverImageCredit} />
        ) : null
      }
      title={post.frontmatter?.title}
      text={<MDXRenderer>{post.body}</MDXRenderer>}
      // header={
      //   <>
      //     <div className="w-auto h-auto rounded-full border-2 border-pink-500">
      //       <img
      //         className="w-12 h-12 object-cover rounded-full shadow cursor-pointer"
      //         alt="User avatar"
      //         src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=200"
      //       />
      //     </div>
      //     <div className="flex flex-col mb-2 ml-4 mt-1">
      //       <div className="text-gray-600 text-sm font-semibold">
      //         Sara Lauren
      //       </div>
      //       <div className="flex w-full mt-1">
      //         <div className="text-blue-700 font-base text-xs mr-1 cursor-pointer">
      //           UX Design
      //         </div>
      //         <div className="text-gray-400 font-thin text-xs">
      //           • 30 seconds ago
      //         </div>
      //       </div>
      //     </div>
      //   </>
      // }
      footer={
        <div className="mt-3 mx-5 flex flex-row">
          <div className="flex text-gray-700 font-normal text-sm rounded-md mb-2 mr-4 items-center">
            Date:
            <div className="ml-1 text-gray-400 font-light text-ms">
              {" "}
              {post.frontmatter.date}
            </div>
          </div>
          {/*<div className="flex text-gray-700 font-normal text-sm rounded-md mb-2 mr-4 items-center">*/}
          {/*  Views:{" "}*/}
          {/*  <div className="ml-1 text-gray-400 font-normal text-ms"> 60k</div>*/}
          {/*</div>*/}
        </div>
      }
    />
  );
};

export default PostItemLarge;
